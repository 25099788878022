<template>
    <div>
        <div v-for="(value) in availableTickets"
             :key="value"
        >
            <ticket v-if="isTicketExists(value)"
                    :result-numbers="resultNumbers"
                    :style="getTicketPosition(value)"

                    :ticket="findTicketByPlace(value)"
                    class="ticket"
            />

            <img v-else
                 :src="disabledTicketImage"

                 :style="getTicketPosition(value)"
                 class="ticket"
            />
        </div>
    </div>
</template>

<script>
import PickKenoTicket from "./PickKenoTicket";

export default {
    props: {
        tickets: {
            type: Array,
            required: true,
            default() {
                return [{
                    bet: null,
                    place: null,
                    numbers: null,
                    totalWin: null,
                    orderCombination: {
                        matchCount: null,
                        matchNumbers: null,
                        win: null,
                    },
                    disorderCombination: {
                        matchCount: null,
                        matchNumbers: null,
                        win: null,
                    },
                    matchNumbers: null,
                }]
            }
        },
        resultNumbers: {
            type: Array,
            required: true,
            default() {
                return []
            }
        }
    },
    data() {
        return {
            availableTickets: [0, 1, 2, 3],
            ticket: {
                width: 356,
                height: 143
            },
            margin: {
                left: 50,
                top: 30,
            }
        }
    },
    computed: {
        disabledTicketImage() {
            return require(`@/assets/games/keno/pick-keno/ticket_disabled_bg.png`);
        }
    },
    methods: {
        getTicketPosition(index) {
            let left = (index % 2 === 1) ? (this.ticket.width + this.margin.left) : 0;
            let top = (index > 1) ? (this.ticket.height + this.margin.top) : 0;

            return {
                left: `${left}px`,
                top: `${top}px`
            };
        },
        isTicketExists(number) {
            return !!this.tickets.find(ticket => ticket.place === number);
        },
        findTicketByPlace(place) {
            return this.tickets.find(ticket => ticket.place === place);
        }
    },
    components: {
        'ticket': PickKenoTicket
    },
}
</script>

<style scoped>
.ticket {
    position: absolute;
}
</style>
